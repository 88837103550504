import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DeleteSvg } from 'svgs/delete.svg';
import { ReactComponent as CopySvg } from 'svgs/copy.svg';
import Fade from '@material-ui/core/Fade';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';

import styles from './ColorBox.module.css';
import { IconButton, makeStyles } from '@material-ui/core';
import WithSvg from 'components/WithSvg/WithSvg';
import { SEND_NOTIFICATION } from 'store/actionTypes/notificationActionTypes';

const useStyles = makeStyles(() => ({
  iconButtonRoot: {
    backgroundColor: '#CCD0D9',
    padding: 7,
    '&:hover': {
      backgroundColor: '#CCD0D9',
    },
  },
}));

const ColorBox = (props) => {
  const { color, isEmpty, selected, showIcons, onDelete, transitionDelay, style } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const [showCopy, setShowCopy] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const toggleShow = useCallback((setState, state) => () => setState(state), []);

  const onCopy = useCallback(() => {
    dispatch({
      type: SEND_NOTIFICATION,
      status: 'success',
      message: 'Successfully copied',
    });
  }, [dispatch]);

  const getColorContent = useCallback(() => {
    return (
      <div className={styles.subContainer} style={{ backgroundColor: color }}>
        {showIcons && (
          <>
            <div
              className={styles.half}
              onMouseLeave={toggleShow(setShowCopy, false)}
              onMouseEnter={toggleShow(setShowCopy, true)}
            >
              <div className={styles.copyWrapper}>
                <Fade in={showCopy}>
                  <CopyToClipboard text={color} onCopy={onCopy}>
                    <IconButton classes={{ root: classes.iconButtonRoot }}>
                      <WithSvg component={CopySvg} size={14} />
                    </IconButton>
                  </CopyToClipboard>
                </Fade>
              </div>
            </div>
            <div
              className={styles.half}
              onMouseLeave={toggleShow(setShowDelete, false)}
              onMouseEnter={toggleShow(setShowDelete, true)}
            >
              <div className={styles.deleteWrapper}>
                <Fade in={showDelete}>
                  <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onDelete}>
                    <WithSvg component={DeleteSvg} size={14} />
                  </IconButton>
                </Fade>
              </div>
            </div>
          </>
        )}
        {!isEmpty && <span className={`${styles.boxLabel} ${selected ? styles.selectedLabelColor : ''}`}>{color}</span>}
      </div>
    );
  }, [classes.iconButtonRoot, color, isEmpty, selected, showIcons, onDelete, onCopy, showCopy, showDelete, toggleShow]);

  return (
    <div className={styles.container} style={style}>
      {transitionDelay === -1 ? (
        getColorContent()
      ) : (
        <Fade in={!isEmpty} style={{ transitionDelay: `${transitionDelay}ms` }}>
          {getColorContent()}
        </Fade>
      )}
    </div>
  );
};

ColorBox.propTypes = {
  color: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool,
  selected: PropTypes.bool,
  showIcons: PropTypes.bool,
  onDelete: PropTypes.func,
  transitionDelay: PropTypes.number,
};

ColorBox.defaultProps = {
  isEmpty: false,
  selected: false,
  showIcons: false,
  onDelete: undefined,
  transitionDelay: -1,
};

export default ColorBox;
