import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import HSLSliderBox from 'components/HSLSliderBox/HSLSliderBox';
import DoubleTextSwitch from 'components/DoubleTextSwitch/DoubleTextSwitch';
import RGBSliderBox from 'components/RGBSliderBox/RGBSliderBox';
import { Button, makeStyles } from '@material-ui/core';
import { ColorContext } from 'context/ColorContext';
import SeedColorPickerModal from 'components/modals/SeedColorPickerModal/SeedColorPickerModal';
import { ReactComponent as PlusSeedColorSvg } from 'svgs/plusSeedColor.svg';
import WithSvg from 'components/WithSvg/WithSvg';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './SlidersBox.module.css';

const sliderWrapperVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeIn',
    },
  },
  exit: {
    display: 'none',
    transition: {
      ease: 'easeIn',
    },
  },
};

const useStyles = makeStyles(() => ({
  buttonRoot: {
    padding: 0,
    minWidth: 'auto',
  },
}));

const SlidersBox = (props) => {
  const { isRgb, toggleIsRgb, onFourHandles, toggleOnFourHandles } = props;

  const classes = useStyles();

  const [isSeedModalOpen, setIsSeedModalOpen] = useState(false);

  const [redBlocked, setRedBlocked] = useState(false);
  const [greenBlocked, setGreenBlocked] = useState(false);
  const [blueBlocked, setBlueBlocked] = useState(false);

  const [isHueBlocked, setIsHueBlocked] = useState(false);
  const [isSaturationBlocked, setIsSaturationBlocked] = useState(false);
  const [isLightnessBlocked, setIsLightnessBlocked] = useState(false);

  const toggleIsSeedModalOpen = useCallback(() => {
    setIsSeedModalOpen(!isSeedModalOpen);
  }, [isSeedModalOpen]);

  const { seedColor } = useContext(ColorContext);

  return (
    <>
      <ErrorBoundary>
        <div className={styles.container}>
          <div className={styles.rgbHslSwitch}>
            <DoubleTextSwitch checked={isRgb} onChange={toggleIsRgb} leftText="HSL" rightText="RGB" id="rgbHsl" />
          </div>
          <div className={styles.colorSelectorWrapper}>
            <Button classes={{ root: classes.buttonRoot }} onClick={toggleIsSeedModalOpen}>
              <div className={styles.colorSelectorContainer}>
                <div
                  className={`${styles.colorSelector} ${seedColor === 'transparent' ? styles.emptyColorSelector : ''}`}
                  style={{ background: seedColor }}
                >
                  {seedColor === 'transparent' && <WithSvg component={PlusSeedColorSvg} size={13} />}
                </div>
              </div>
            </Button>
          </div>
          <div className={styles.noHandlesSwitch}>
            <DoubleTextSwitch
              checked={onFourHandles}
              onChange={toggleOnFourHandles}
              leftText="2Th"
              rightText="4Th"
              id="2Th4Th"
            />
          </div>
          <AnimatePresence exitBeforeEnter>
            {isRgb && (
              <motion.div
                variants={sliderWrapperVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={styles.sliderContainer}
              >
                <RGBSliderBox
                  redBlocked={redBlocked}
                  setRedBlocked={setRedBlocked}
                  greenBlocked={greenBlocked}
                  setGreenBlocked={setGreenBlocked}
                  blueBlocked={blueBlocked}
                  setBlueBlocked={setBlueBlocked}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            {!isRgb && (
              <motion.div
                variants={sliderWrapperVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={styles.sliderContainer}
              >
                <HSLSliderBox
                  isHueBlocked={isHueBlocked}
                  setIsHueBlocked={setIsHueBlocked}
                  isSaturationBlocked={isSaturationBlocked}
                  setIsSaturationBlocked={setIsSaturationBlocked}
                  isLightnessBlocked={isLightnessBlocked}
                  setIsLightnessBlocked={setIsLightnessBlocked}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <SeedColorPickerModal
          toggleOnFourHandles={toggleOnFourHandles}
          onFourHandles={onFourHandles}
          open={isSeedModalOpen}
          onClose={toggleIsSeedModalOpen}
        />
      </ErrorBoundary>
    </>
  );
};

SlidersBox.propTypes = {
  isRgb: PropTypes.bool.isRequired,
  toggleIsRgb: PropTypes.func.isRequired,
  onFourHandles: PropTypes.bool.isRequired,
  toggleOnFourHandles: PropTypes.func.isRequired,
};

export default SlidersBox;
