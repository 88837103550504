import React, { useCallback, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';

import ColorSlider from 'components/ColorSlider/ColorSlider';
import { ColorContext } from 'context/ColorContext';
import {
  generateHslTrackGradient,
  generateCSSHueGradient,
  getSaturationBackground,
  getLightnessBackground,
  getHslHandleBasedOnSeedColor,
} from 'utils/colorHelper';
import {
  HUE_START,
  HUE_END,
  SATURATION_END,
  LIGHTNESS_END,
  HUE_MIDDLE,
  SATURATION_MIDDLE,
  LIGHTNESS_MIDDLE,
  SATURATION_START,
  LIGHTNESS_START,
} from 'utils/constants';
import BlockButton from 'components/BlockButton/BlockButton';
import { changeSliderValue } from 'utils/helpers';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import styles from './HSLSliderBox.module.css';

const getHueBackground = () => generateCSSHueGradient();

const HSLSliderBox = (props) => {
  const {
    isHueBlocked,
    setIsHueBlocked,
    isSaturationBlocked,
    setIsSaturationBlocked,
    isLightnessBlocked,
    setIsLightnessBlocked,
  } = props;

  const hueSliderRef = useRef();
  const saturationSliderRef = useRef();
  const lightnessSliderRef = useRef();

  const { hsl, seedColor, changeRgbBasedOnHsl } = useContext(ColorContext);
  const {
    hue,
    saturation,
    lightness,
    setHue,
    setSaturation,
    setLightness,
    hueMiddle,
    saturationMiddle,
    lightnessMiddle,
    oldHue,
    setOldHue,
    oldSaturation,
    setOldSaturation,
    oldLightness,
    setOldLightness,
  } = hsl;

  useEffect(() => {
    if (isHueBlocked && hue.length > 1) {
      setIsHueBlocked(false);
    }
    if (isSaturationBlocked && saturation.length > 1) {
      setIsSaturationBlocked(false);
    }
    if (isLightnessBlocked && lightness.length > 1) {
      setIsLightnessBlocked(false);
    }
  }, [
    isHueBlocked,
    isSaturationBlocked,
    isLightnessBlocked,
    hue,
    saturation,
    lightness,
    setIsHueBlocked,
    setIsSaturationBlocked,
    setIsLightnessBlocked,
  ]);

  useEffect(() => {
    if (hue.length === 1 && hueSliderRef && hueSliderRef.current) {
      hueSliderRef.current.firstChild.style.background = 'transparent';
      return;
    }
    if (hueSliderRef && hueSliderRef.current)
      hueSliderRef.current.firstChild.style.background = generateHslTrackGradient(hue, HUE_END);
  }, [hue]);

  useEffect(() => {
    const hueValue = Math.round((hue[0] + hue[hue.length - 1]) / 2);
    if (saturation.length === 1 && saturationSliderRef && saturationSliderRef.current) {
      saturationSliderRef.current.firstChild.style.background = 'transparent';
      saturationSliderRef.current.style.background = getSaturationBackground(hueValue);
      return;
    }
    if (saturationSliderRef && saturationSliderRef.current) {
      saturationSliderRef.current.firstChild.style.background = generateHslTrackGradient(saturation, SATURATION_END);
      saturationSliderRef.current.style.background = getSaturationBackground(hueValue);
    }
  }, [saturation, hue, isSaturationBlocked]);

  useEffect(() => {
    const hueValue = Math.round((hue[0] + hue[hue.length - 1]) / 2);
    if (lightness.length === 1 && saturationSliderRef && saturationSliderRef.current) {
      lightnessSliderRef.current.firstChild.style.background = 'transparent';
      lightnessSliderRef.current.style.background = getLightnessBackground(hueValue);
      return;
    }
    if (lightnessSliderRef && lightnessSliderRef.current) {
      lightnessSliderRef.current.firstChild.style.background = generateHslTrackGradient(lightness, LIGHTNESS_END);
      lightnessSliderRef.current.style.background = getLightnessBackground(hueValue);
    }
  }, [hue, lightness]);

  const onChangeHue = useCallback(
    (e, value) => {
      changeSliderValue(value, setHue, hueMiddle, seedColor !== 'transparent');
      changeRgbBasedOnHsl(value, saturation, lightness);
    },
    [setHue, hueMiddle, seedColor, changeRgbBasedOnHsl, saturation, lightness],
  );

  const onChangeSaturation = useCallback(
    (e, value) => {
      changeSliderValue(value, setSaturation, saturationMiddle, seedColor !== 'transparent');
      changeRgbBasedOnHsl(hue, value, lightness);
    },
    [setSaturation, saturationMiddle, seedColor, changeRgbBasedOnHsl, hue, lightness],
  );

  const onChangeLightness = useCallback(
    (e, value) => {
      changeSliderValue(value, setLightness, lightnessMiddle, seedColor !== 'transparent');
      changeRgbBasedOnHsl(hue, saturation, value);
    },
    [setLightness, lightnessMiddle, seedColor, changeRgbBasedOnHsl, hue, saturation],
  );

  const getMultipleHandlesValue = useCallback(
    (indexForColor, oldValue, maxValue) => {
      if (hueMiddle > 0 && saturationMiddle > 0 && lightnessMiddle > 0) {
        return oldValue;
      }
      return getHslHandleBasedOnSeedColor(indexForColor, seedColor, maxValue, maxValue === LIGHTNESS_END);
    },
    [saturationMiddle, lightnessMiddle, hueMiddle, seedColor],
  );
  // old value problem when all blocked and change between hsl and rgb and then unblock
  const onClickBlocked = useCallback(
    (bool, setBool, setValue, middle, value, oldValue, setOldValue, indexForColor, maxValue) => () => {
      const newBool = !bool;
      let newValue;

      if (newBool) {
        // check if seed color is set
        const newSeedColor = seedColor !== 'transparent' ? chroma(seedColor).hsl()[indexForColor] : 'transparent';
        newValue =
          seedColor === 'transparent'
            ? [middle]
            : [Math.round(indexForColor === 0 ? newSeedColor : newSeedColor * 100)];
        // eslint-disable-next-line no-param-reassign
        setOldValue(value);
        setValue(newValue);
      } else {
        // check if seed color is set
        newValue = seedColor !== 'transparent' ? getMultipleHandlesValue(indexForColor, oldValue, maxValue) : oldValue;
        setValue(newValue);
      }

      changeRgbBasedOnHsl(
        indexForColor === 0 ? newValue : hue,
        indexForColor === 1 ? newValue : saturation,
        indexForColor === 2 ? newValue : lightness,
      );
      setBool(newBool);
    },
    [changeRgbBasedOnHsl, getMultipleHandlesValue, hue, lightness, saturation, seedColor],
  );

  return (
    <ErrorBoundary>
      <div className={styles.container}>
        <div className={styles.sliderContainer}>
          <ColorSlider
            range={[HUE_START, HUE_END]}
            sliderProps={{
              disabled: isHueBlocked && seedColor !== 'transparent',
              backgroundGradient: getHueBackground(),
            }}
            value={hue}
            onChange={onChangeHue}
            refSlider={hueSliderRef}
            min={HUE_START}
            max={HUE_END}
            middleValue={hueMiddle}
            id="hue"
          />
          <BlockButton
            isBlocked={isHueBlocked}
            onClick={onClickBlocked(
              isHueBlocked,
              setIsHueBlocked,
              setHue,
              HUE_MIDDLE,
              hue,
              oldHue,
              setOldHue,
              0,
              HUE_END,
            )}
          />
        </div>
        <div className={styles.sliderContainer}>
          <ColorSlider
            range={[SATURATION_START, SATURATION_END]}
            sliderProps={{
              disabled: isSaturationBlocked && seedColor !== 'transparent',
              backgroundGradient: getSaturationBackground(HUE_MIDDLE),
            }}
            value={saturation}
            onChange={onChangeSaturation}
            refSlider={saturationSliderRef}
            min={SATURATION_START}
            max={SATURATION_END}
            middleValue={saturationMiddle}
          />
          <BlockButton
            isBlocked={isSaturationBlocked}
            onClick={onClickBlocked(
              isSaturationBlocked,
              setIsSaturationBlocked,
              setSaturation,
              SATURATION_MIDDLE,
              saturation,
              oldSaturation,
              setOldSaturation,
              1,
              SATURATION_END,
            )}
          />
        </div>
        <div className={styles.sliderContainer}>
          <ColorSlider
            range={[LIGHTNESS_START, LIGHTNESS_END]}
            sliderProps={{
              disabled: isLightnessBlocked && seedColor !== 'transparent',
              backgroundGradient: getLightnessBackground(HUE_MIDDLE),
            }}
            value={lightness}
            onChange={onChangeLightness}
            refSlider={lightnessSliderRef}
            min={LIGHTNESS_START}
            max={LIGHTNESS_END}
            middleValue={lightnessMiddle}
            isBlocked={isLightnessBlocked}
          />
          <BlockButton
            isBlocked={isLightnessBlocked}
            onClick={onClickBlocked(
              isLightnessBlocked,
              setIsLightnessBlocked,
              setLightness,
              LIGHTNESS_MIDDLE,
              lightness,
              oldLightness,
              setOldLightness,
              2,
              LIGHTNESS_END,
            )}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

HSLSliderBox.propTypes = {
  isHueBlocked: PropTypes.bool.isRequired,
  setIsHueBlocked: PropTypes.func.isRequired,
  isSaturationBlocked: PropTypes.bool.isRequired,
  setIsSaturationBlocked: PropTypes.func.isRequired,
  isLightnessBlocked: PropTypes.bool.isRequired,
  setIsLightnessBlocked: PropTypes.func.isRequired,
};

export default HSLSliderBox;
