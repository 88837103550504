import React, { useContext, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import chroma from 'chroma-js';
import {
  RGB_START,
  RGB_END,
  RGB_MIDDLE,
  RED_GRADIENT_CSS,
  GREEN_GRADIENT_CSS,
  BLUE_GRADIENT_CSS,
} from 'utils/constants';
import { ColorContext } from 'context/ColorContext';
import { generateRgbTrackGradient, getRgbHandleBasedOnSeedColor } from 'utils/colorHelper';
import BlockButton from 'components/BlockButton/BlockButton';
import ColorSlider from 'components/ColorSlider/ColorSlider';
import { changeSliderValue } from 'utils/helpers';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import styles from './RGBSliderBox.module.css';

const RGBSliderBox = (props) => {
  const { redBlocked, setRedBlocked, greenBlocked, setGreenBlocked, blueBlocked, setBlueBlocked } = props;

  const redSliderRef = useRef();
  const greenSliderRef = useRef();
  const blueSliderRef = useRef();

  const { rgb, seedColor, changeHslBasedOnRgb } = useContext(ColorContext);
  const {
    red,
    blue,
    green,
    setRed,
    setGreen,
    setBlue,
    redMiddle,
    greenMiddle,
    blueMiddle,
    oldRed,
    setOldRed,
    oldGreen,
    setOldGreen,
    oldBlue,
    setOldBlue,
  } = rgb;

  useEffect(() => {
    if (redBlocked && red.length > 1) {
      setRedBlocked(false);
    }
    if (greenBlocked && green.length > 1) {
      setGreenBlocked(false);
    }
    if (blueBlocked && blue.length > 1) {
      setBlueBlocked(false);
    }
  }, [redBlocked, greenBlocked, blueBlocked, red, green, blue, setBlueBlocked, setGreenBlocked, setRedBlocked]);

  useEffect(() => {
    if (red.length === 1 && redSliderRef && redSliderRef.current) {
      redSliderRef.current.firstChild.style.background = 'transparent';
      return;
    }
    if (redSliderRef && redSliderRef.current)
      redSliderRef.current.firstChild.style.background = generateRgbTrackGradient(red);
  }, [red]);

  useEffect(() => {
    if (green.length === 1 && greenSliderRef && greenSliderRef.current) {
      greenSliderRef.current.firstChild.style.background = 'transparent';
      return;
    }
    if (greenSliderRef && greenSliderRef.current)
      greenSliderRef.current.firstChild.style.background = generateRgbTrackGradient(green);
  }, [green]);

  useEffect(() => {
    if (blue.length === 1 && blueSliderRef && blueSliderRef.current) {
      blueSliderRef.current.firstChild.style.background = 'transparent';
      return;
    }
    if (blueSliderRef && blueSliderRef.current)
      blueSliderRef.current.firstChild.style.background = generateRgbTrackGradient(blue);
  }, [blue]);

  const onChangeRed = useCallback(
    (e, value) => {
      changeSliderValue(value, setRed, redMiddle, seedColor !== 'transparent');
      changeHslBasedOnRgb(value, green, blue);
    },
    [setRed, redMiddle, seedColor, changeHslBasedOnRgb, green, blue],
  );

  const onChangeGreen = useCallback(
    (e, value) => {
      changeSliderValue(value, setGreen, greenMiddle, seedColor !== 'transparent');
      changeHslBasedOnRgb(red, value, blue);
    },
    [setGreen, greenMiddle, seedColor, changeHslBasedOnRgb, red, blue],
  );

  const onChangeBlue = useCallback(
    (e, value) => {
      changeSliderValue(value, setBlue, blueMiddle, seedColor !== 'transparent');
      changeHslBasedOnRgb(red, green, value);
    },
    [setBlue, blueMiddle, seedColor, changeHslBasedOnRgb, red, green],
  );

  const getMultipleHandlesValue = useCallback(
    (indexForColor, oldValue) => {
      if (redMiddle > 0 && blueMiddle > 0 && greenMiddle > 0) {
        return oldValue;
      }
      return getRgbHandleBasedOnSeedColor(indexForColor, seedColor, RGB_END);
    },
    [blueMiddle, greenMiddle, redMiddle, seedColor],
  );

  const onClickBlocked = useCallback(
    (bool, setBool, setValue, middle, value, oldValue, setOldValue, indexForColor) => () => {
      const newBool = !bool;
      let newValue;

      if (newBool) {
        // check if seed color is set
        newValue = seedColor === 'transparent' ? [middle] : [Math.round(chroma(seedColor).rgb()[indexForColor])];
        // eslint-disable-next-line no-param-reassign
        setOldValue(value);
        setValue(newValue);
      } else {
        // check if seed color is set
        newValue = seedColor !== 'transparent' ? getMultipleHandlesValue(indexForColor, oldValue) : oldValue;

        setValue(newValue);
      }

      changeHslBasedOnRgb(
        indexForColor === 0 ? newValue : red,
        indexForColor === 1 ? newValue : green,
        indexForColor === 2 ? newValue : blue,
      );
      setBool(newBool);
    },
    [changeHslBasedOnRgb, red, green, blue, seedColor, getMultipleHandlesValue],
  );

  return (
    <ErrorBoundary>
      <div className={styles.container}>
        <div className={styles.sliderContainer}>
          <ColorSlider
            range={[RGB_START, RGB_END]}
            sliderProps={{
              disabled: redBlocked && seedColor !== 'transparent',
              backgroundGradient: RED_GRADIENT_CSS,
            }}
            refSlider={redSliderRef}
            value={red}
            min={RGB_START}
            max={RGB_END}
            onChange={onChangeRed}
            middleValue={redMiddle}
            id="red"
          />
          <BlockButton
            isBlocked={redBlocked}
            onClick={onClickBlocked(redBlocked, setRedBlocked, setRed, RGB_MIDDLE, red, oldRed, setOldRed, 0)}
          />
        </div>
        <div className={styles.sliderContainer}>
          <ColorSlider
            range={[RGB_START, RGB_END]}
            sliderProps={{
              disabled: greenBlocked && seedColor !== 'transparent',
              backgroundGradient: GREEN_GRADIENT_CSS,
            }}
            refSlider={greenSliderRef}
            value={green}
            min={RGB_START}
            max={RGB_END}
            onChange={onChangeGreen}
            middleValue={greenMiddle}
            id="green"
          />
          <BlockButton
            isBlocked={greenBlocked}
            onClick={onClickBlocked(
              greenBlocked,
              setGreenBlocked,
              setGreen,
              RGB_MIDDLE,
              green,
              oldGreen,
              setOldGreen,
              1,
            )}
          />
        </div>
        <div className={styles.sliderContainer}>
          <ColorSlider
            range={[RGB_START, RGB_END]}
            sliderProps={{
              disabled: blueBlocked && seedColor !== 'transparent',
              backgroundGradient: BLUE_GRADIENT_CSS,
            }}
            refSlider={blueSliderRef}
            value={blue}
            min={RGB_START}
            max={RGB_END}
            onChange={onChangeBlue}
            middleValue={blueMiddle}
            id="blue"
          />
          <BlockButton
            isBlocked={blueBlocked}
            onClick={onClickBlocked(blueBlocked, setBlueBlocked, setBlue, RGB_MIDDLE, blue, oldBlue, setOldBlue, 2)}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

RGBSliderBox.propTypes = {
  redBlocked: PropTypes.bool.isRequired,
  setRedBlocked: PropTypes.func.isRequired,
  greenBlocked: PropTypes.bool.isRequired,
  setGreenBlocked: PropTypes.func.isRequired,
  blueBlocked: PropTypes.bool.isRequired,
  setBlueBlocked: PropTypes.func.isRequired,
};

export default RGBSliderBox;
