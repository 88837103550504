import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Button, Grid, Divider, makeStyles, Badge } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ReactComponent as RandomSvg } from 'svgs/random.svg';
import { ReactComponent as RefreshSvg } from 'svgs/refresh.svg';
import { ReactComponent as SaveSvg } from 'svgs/save.svg';
import ColorBox from 'components/ColorBox/ColorBox';
import { getRandomHslColor, getRandomRgbColor } from 'utils/colorHelper';
import WithSvg from 'components/WithSvg/WithSvg';
import { ColorContext } from 'context/ColorContext';
import { SAVE_COLORS, SAVE_COLORS_FOR_ANIMATION } from 'store/actionTypes/colorActionTypes';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { motion } from 'framer-motion';
import { NO_RANDOM_COLORS, DELAY } from 'utils/constants';
import styles from './ColorBoxGenerator.module.css';

const resetVariants = {
  rotate: {
    rotate: [0, -360],
    transition: {
      duration: 0.3,
    },
  },
};

const generateVariants = {
  flash: {
    opacity: [0, 1],
    transition: {
      yoyo: Infinity,
      duration: 0.6,
    },
  },
  finish: {
    opacity: 1,
  },
};

const colorInitialState = ['empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty'];
const selectedColorInitialState = ['', '', '', '', '', '', '', '', ''];

const useStyles = makeStyles(() => ({
  button: {
    flexGrow: 1,
    padding: '10px 0',
  },
  divider: {
    height: '70%',
    marginTop: '2%',
  },
  badge: {
    background: 'transparent',
    color: 'black',
    fontSize: '0.6em',
    right: '-7px',
    fontWeight: '400',
  },
}));

const getUnselectedColors = (generatedColors, selectedColors) => {
  const newGeneratedColors = [];

  for (let i = 0; i < generatedColors.length; i += 1) {
    if (generatedColors[i] === selectedColors[i]) {
      newGeneratedColors.push('empty');
    } else {
      newGeneratedColors.push(generatedColors[i]);
    }
  }

  return newGeneratedColors;
};

const ColorBoxGenerator = (props) => {
  const { isRgb, onFourHandles, toggleOnFourHandles } = props;

  const classes = useStyles();

  const { hsl, rgb, setSeedColor } = useContext(ColorContext);
  const { hue, saturation, lightness, resetHslState } = hsl;
  const { red, blue, green, resetRgbState } = rgb;

  const [generatedColors, setGeneratedColors] = useState(colorInitialState);
  const [selectedColors, setSelectedColors] = useState(selectedColorInitialState);
  const [noSelectedColors, setNoSelectedColors] = useState(0);

  const [disabledReset, setDisabledReset] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);

  const [animateGenerate, setAnimateGenerate] = useState(false);
  const [animateReset, setAnimateReset] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedColors.some((color) => color !== '')) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [selectedColors]);

  const getGeneratedColor = useCallback(() => {
    let generatedColor;

    if (!isRgb) {
      generatedColor = getRandomHslColor(hue, saturation, lightness);
    } else {
      generatedColor = getRandomRgbColor(red, green, blue);
    }

    return generatedColor;
  }, [hue, saturation, lightness, red, green, blue, isRgb]);

  const generateColors = useCallback(() => {
    setAnimateGenerate(true);
    setDisabledReset(true);
    setDisabledSave(true);
    setSelectedColors(selectedColorInitialState);

    const colors = [];
    for (let i = 0; i < NO_RANDOM_COLORS; i += 1) {
      colors.push(getGeneratedColor(colors));
    }

    setNoSelectedColors(0);
    setGeneratedColors(colors);

    setTimeout(() => {
      setAnimateGenerate(false);
      setDisabledReset(false);
    }, NO_RANDOM_COLORS * DELAY);
  }, [getGeneratedColor]);

  const resetRanges = useCallback(() => {
    resetHslState();
    resetRgbState();
  }, [resetHslState, resetRgbState]);

  const resetState = useCallback(
    (resetColors = false, newGeneratedColors = undefined) => {
      if (newGeneratedColors) setGeneratedColors(newGeneratedColors);
      else setGeneratedColors(colorInitialState);

      setSelectedColors(selectedColorInitialState);
      setAnimateGenerate(false);
      setNoSelectedColors(0);
      if (onFourHandles) toggleOnFourHandles();

      if (resetColors) {
        resetRanges();
        setSeedColor('transparent');
      }
    },
    [setSeedColor, resetRanges, onFourHandles, toggleOnFourHandles],
  );

  const onClickColor = useCallback(
    (index) => {
      const newSelectedColors = [...selectedColors];
      if (newSelectedColors[index]) {
        newSelectedColors[index] = '';
        setSelectedColors(newSelectedColors);
        if (noSelectedColors > 0) {
          setNoSelectedColors(noSelectedColors - 1);
        }
      } else {
        newSelectedColors[index] = generatedColors[index];
        setSelectedColors(newSelectedColors);
        setNoSelectedColors(noSelectedColors + 1);
      }
    },
    [generatedColors, selectedColors, noSelectedColors],
  );

  const saveColors = useCallback(
    (event) => {
      const validColors = selectedColors.filter((color) => color !== '');
      const newGeneratedColors = getUnselectedColors(generatedColors, selectedColors);
      if (validColors.length > 0) {
        dispatch({
          type: SAVE_COLORS_FOR_ANIMATION,
          colors: validColors,
        });
        if (event.screenX && event.screenX !== 0 && event.screenY && event.screenY !== 0) {
          dispatch({
            type: SAVE_COLORS,
            colors: validColors,
          });
          resetState(false, newGeneratedColors);
        } else {
          setGeneratedColors(newGeneratedColors);
        }
      }
    },
    [dispatch, selectedColors, generatedColors, resetState],
  );

  const getColorBoxes = useCallback(() => {
    let delay = 0;
    const colorBoxes = [];

    for (let i = 0; i < generatedColors.length; i += 1) {
      const color = generatedColors[i];
      if (color === 'empty') {
        colorBoxes.push(
          <Grid item xs={4} key={`${color}${i}`}>
            <ColorBox color="#fff" isEmpty transitionDelay={delay} />
          </Grid>,
        );
      } else {
        colorBoxes.push(
          <Grid id={`colorBox${i}`} item xs={4} key={`${color}${i}`} onClick={() => onClickColor(i)}>
            <ColorBox
              selected={!!selectedColors[i]}
              color={color}
              transitionDelay={delay}
              style={{ cursor: 'pointer' }}
            />
          </Grid>,
        );
      }
      delay += DELAY;
    }
    return colorBoxes;
  }, [generatedColors, selectedColors, onClickColor]);

  const onReset = useCallback(() => {
    setAnimateReset(true);
    resetState(true);
    setTimeout(() => {
      setAnimateReset(false);
    }, 300);
  }, [resetState]);

  return (
    <ErrorBoundary>
      <div className={styles.container}>
        <div className={styles.header}>
          <Button disabled={disabledReset} onClick={onReset} classes={{ root: classes.button }} id="resetColors">
            <motion.div variants={resetVariants} animate={animateReset ? 'rotate' : ''}>
              <WithSvg disabled={disabledReset} component={RefreshSvg} size={20} />
            </motion.div>
          </Button>
          <Divider orientation="vertical" classes={{ root: classes.divider }} />
          <Button
            onClick={generateColors}
            classes={{ root: classes.button }}
            disabled={animateGenerate}
            id="generateColors"
          >
            <motion.div variants={generateVariants} animate={animateGenerate ? 'flash' : 'finish'}>
              <WithSvg component={RandomSvg} size={24} />
            </motion.div>
          </Button>
          <Divider orientation="vertical" classes={{ root: classes.divider }} />
          <Button disabled={disabledSave} classes={{ root: classes.button }} onClick={saveColors} id="saveColors">
            <Badge badgeContent={`(${noSelectedColors})`} classes={{ badge: classes.badge }}>
              <WithSvg disabled={disabledSave} component={SaveSvg} size={20} />
            </Badge>
          </Button>
        </div>
        <div className={styles.colorsContainer}>
          <Grid container spacing={2}>
            {getColorBoxes()}
          </Grid>
        </div>
      </div>
    </ErrorBoundary>
  );
};

ColorBoxGenerator.propTypes = {
  isRgb: PropTypes.bool.isRequired,
  onFourHandles: PropTypes.bool.isRequired,
  toggleOnFourHandles: PropTypes.func.isRequired,
};

export default ColorBoxGenerator;
