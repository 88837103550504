import chroma from 'chroma-js';
import { randomIntFromRanges } from './helpers';
import { RGB_END } from './constants';

export const getHexColor = (color) => {
  return chroma(color).hex();
};

export const getRandomHslColor = (hue, saturation, lightness) => {
  return chroma({
    h: hue.length === 1 ? hue[0] : randomIntFromRanges(hue),
    s: saturation.length === 1 ? saturation[0] / 100 : randomIntFromRanges(saturation) / 100,
    l: lightness.length === 1 ? lightness[0] / 100 : randomIntFromRanges(lightness) / 100,
  }).hex();
};

export const getRandomRgbColor = (red, green, blue) => {
  return chroma({
    r: red.length === 1 ? red[0] : randomIntFromRanges(red),
    g: green.length === 1 ? green[0] : randomIntFromRanges(green),
    b: blue.length === 1 ? blue[0] : randomIntFromRanges(blue),
  }).hex();
};

export const generateCSSHueGradient = () => {
  let gradientBuilder = 'linear-gradient(to right,';
  for (let i = 0; i < 360; i += 30) {
    gradientBuilder = `${gradientBuilder} hsl(${i}, 100%, 50%),`;
  }
  gradientBuilder = `${gradientBuilder} hsl(360, 100%, 50%))`;

  return gradientBuilder;
};

export const generateRgbTrackGradient = (values) => {
  let backgroundGradient = 'linear-gradient(to right';

  for (let i = 0; i < values.length; i += 2) {
    const firstPercent = (values[i] / RGB_END) * 100;
    const secondPercent = (values[i + 1] / RGB_END) * 100;

    backgroundGradient = `${backgroundGradient}, rgba(0,0,0,0.35), rgba(0,0,0,0.35) ${firstPercent}%,  rgba(0,0,0,0) ${firstPercent}%, rgba(0,0,0,0) ${secondPercent}%, rgba(0,0,0,0.35) ${secondPercent}%`;
  }

  backgroundGradient = `${backgroundGradient})`;

  return backgroundGradient;
};

export const generateHslTrackGradient = (values, end) => {
  let backgroundGradient = 'linear-gradient(to right';

  for (let i = 0; i < values.length; i += 2) {
    const firstPercent = (values[i] / end) * 100;
    const secondPercent = (values[i + 1] / end) * 100;

    backgroundGradient = `${backgroundGradient}, rgba(0,0,0,0.35), rgba(0,0,0,0.35) ${firstPercent}%,  rgba(0,0,0,0) ${firstPercent}%, rgba(0,0,0,0) ${secondPercent}%, rgba(0,0,0,0.35) ${secondPercent}%`;
  }

  backgroundGradient = `${backgroundGradient})`;

  return backgroundGradient;
};

export const getSaturationBackground = (hueValue) =>
  `linear-gradient(to right, hsl(${hueValue}, 0%, 50%), hsl(${hueValue}, 100%, 50%))`;

export const getLightnessBackground = (hueValue) =>
  `linear-gradient(to right, hsl(${hueValue}, 100%, 0%), hsl(${hueValue}, 100%, 50%), hsl(${hueValue}, 100%, 100%))`;

export const getHslHandleBasedOnSeedColor = (index, seedColor, maxValue, isPercent = false) => {
  const seedColorHsl = chroma(seedColor).hsl();

  let value;
  if (isPercent) {
    value = Math.round(seedColorHsl[index] * 100);
  } else {
    value = Math.round(seedColorHsl[index]);
  }
  const tenthOfTheMaxValue = 0.1 * maxValue;

  const firstValue = value - tenthOfTheMaxValue < 0 ? 0 : Math.round(value - tenthOfTheMaxValue);
  const lastValue = value + tenthOfTheMaxValue > maxValue ? maxValue : Math.round(value + tenthOfTheMaxValue);

  return [firstValue, lastValue];
};

export const getRgbHandleBasedOnSeedColor = (index, seedColor, maxValue) => {
  const seedColorHsl = chroma(seedColor).rgb();

  const value = Math.round(seedColorHsl[index]);

  const tenthOfTheMaxValue = 0.1 * maxValue;

  const firstValue = value - tenthOfTheMaxValue < 0 ? 0 : Math.round(value - tenthOfTheMaxValue);
  const lastValue = value + tenthOfTheMaxValue > maxValue ? maxValue : Math.round(value + tenthOfTheMaxValue);

  return [firstValue, lastValue];
};

const getValue = (value) => {
  // eslint-disable-next-line no-restricted-globals
  return isNaN(value) ? 0 : value;
};

const numberSort = (a, b) => a - b;

const getColorValue = (colorArray, index, isUnitInterval = false) => {
  if (colorArray.length === 1) return isUnitInterval ? colorArray[0] / 100 : colorArray[0];
  return isUnitInterval ? colorArray[index] / 100 : colorArray[index];
};

const createRgbObject = (red, green, blue, index) => {
  const rgb = {};

  rgb.r = getColorValue(red, index);
  rgb.g = getColorValue(green, index);
  rgb.b = getColorValue(blue, index);

  return rgb;
};

const createHslObject = (hue, saturation, lightness, index) => {
  const hsl = {};

  hsl.h = getColorValue(hue, index);
  hsl.s = getColorValue(saturation, index, true);
  hsl.l = getColorValue(lightness, index, true);

  return hsl;
};

export const getHslBasedOnRgbArrays = (red, green, blue) => {
  const length = Math.max(red.length, green.length, blue.length);
  const newHue = [];
  const newSaturation = [];
  const newLightness = [];

  for (let i = 0; i < length; i += 1) {
    const hsl = chroma(createRgbObject(red, green, blue, i)).hsl();

    newHue.push(Math.round(getValue(hsl[0])));
    newSaturation.push(Math.round(getValue(hsl[1]) * 100));
    newLightness.push(Math.round(getValue(hsl[2]) * 100));
  }

  newHue.sort(numberSort);
  newSaturation.sort(numberSort);
  newLightness.sort(numberSort);

  return [newHue, newSaturation, newLightness];
};

export const getRgbBasedOnHslArrays = (hue, saturation, lightness) => {
  const length = Math.max(hue.length, saturation.length, lightness.length);
  const newRed = [];
  const newGreen = [];
  const newBlue = [];

  for (let i = 0; i < length; i += 1) {
    const rgb = chroma(createHslObject(hue, saturation, lightness, i)).rgb();

    newRed.push(Math.round(getValue(rgb[0])));
    newGreen.push(Math.round(getValue(rgb[1])));
    newBlue.push(Math.round(getValue(rgb[2])));
  }

  newRed.sort(numberSort);
  newGreen.sort(numberSort);
  newBlue.sort(numberSort);

  return [newRed, newGreen, newBlue];
};
