import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Switch, Grid } from '@material-ui/core';
import { PRIMARY_COLOR } from 'utils/constants';

const CustomSwitch = withStyles({
  switchBase: {
    color: PRIMARY_COLOR,
    '&$checked': {
      color: PRIMARY_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: PRIMARY_COLOR,
    },
  },
  checked: {},
  track: {},
})(Switch);

const DoubleTextSwitch = (props) => {
  const { checked, onChange, leftText, rightText, disabled, id } = props;

  return (
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>{leftText}</Grid>
      <Grid item>
        <CustomSwitch checked={checked} onChange={onChange} disabled={disabled} id={id} />
      </Grid>
      <Grid item>{rightText}</Grid>
    </Grid>
  );
};

DoubleTextSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  leftText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

DoubleTextSwitch.defaultProps = {
  disabled: false,
  id: 'doubleTextSwitch',
};

export default DoubleTextSwitch;
