import React from 'react';
import PropTypes from 'prop-types';

import { AppBar, Toolbar, Typography, makeStyles } from '@material-ui/core';
import Palette from 'components/Palette/Palette';
import HelpButton from 'components/HelpButton/HelpButton';
import styles from './ApplicationBar.module.css';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  toolbar: {
    background: theme.palette.primary,
    paddingRight: '5px',
  },
}));

const ApplicationBar = (props) => {
  const { setAppKey } = props;

  const classes = useStyles();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.palette}>
          <Palette />
        </div>
        <div className={styles.helpButton}>
          <HelpButton setAppKey={setAppKey} />
        </div>
        <AppBar position="static">
          <Toolbar classes={{ root: classes.toolbar }}>
            <Typography variant="h6" className={classes.title}>
              COLORCHANCE.COM
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

ApplicationBar.propTypes = {
  setAppKey: PropTypes.func.isRequired,
};

export default ApplicationBar;
