import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';

import RangeSelector from 'components/RangeSelector/RangeSelector';
import WithSvg from 'components/WithSvg/WithSvg';
import { ReactComponent as PlusSvg } from 'svgs/plus.svg';
import { ReactComponent as MinusSvg } from 'svgs/minus.svg';
import { PRIMARY_COLOR } from 'utils/constants';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './SliderWrapper.module.css';

const sliderWrapperVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeIn',
    },
  },
  exit: {
    display: 'none',
  },
};

const useStyles = makeStyles(() => ({
  removeButtonRoot: {
    padding: 7,
    marginRight: 5,
    background: PRIMARY_COLOR,
    '&:hover': {
      background: PRIMARY_COLOR,
    },
  },
  addButtonRoot: {
    padding: 7,
    marginLeft: 5,
    background: PRIMARY_COLOR,
    '&:hover': {
      background: PRIMARY_COLOR,
    },
  },
}));

const getRange = (marginRange, value, i) => {
  if (i === 0) {
    return [marginRange[0], value[i + 1]];
  }
  if (i + 1 >= value.length) {
    return [value[i - 1], marginRange[1]];
  }
  return [value[i - 1], value[i + 1]];
};

const SliderWrapper = (props) => {
  const {
    children,
    value,
    onChange,
    onAdd,
    onRemove,
    range,
    maintainSelectorHeight,
    disableRangeSelector,
    className,
  } = props;

  const classes = useStyles();

  const rangeSelectors = [];

  let isSingle = false;

  if (value.length === 1) {
    isSingle = true;
    rangeSelectors.push(
      <RangeSelector
        key="first"
        range={range}
        value={value[0]}
        onChange={onChange[0]}
        onClickAdd={onAdd[0]}
        onClickRemove={onRemove[0]}
      />,
    );
  } else if (value.length === 2) {
    rangeSelectors.push(
      <React.Fragment key="firstFragment">
        <RangeSelector
          key="first"
          range={range}
          value={value[0]}
          onChange={onChange[0]}
          onClickAdd={onAdd[0]}
          onClickRemove={onRemove[0]}
        />
        <RangeSelector
          key="second"
          range={range}
          value={value[1]}
          onChange={onChange[1]}
          onClickAdd={onAdd[1]}
          onClickRemove={onRemove[1]}
        />
      </React.Fragment>,
    );
  } else if (value.length === 3) {
    rangeSelectors.push(
      <React.Fragment key="firstFragment">
        <RangeSelector
          key="first"
          range={[range[0], value[1]]}
          value={value[0]}
          onChange={onChange[0]}
          onClickAdd={onAdd[0]}
          onClickRemove={onRemove[0]}
        />
        <RangeSelector
          key="second"
          range={[value[1], range[1]]}
          value={value[2]}
          onChange={onChange[2]}
          onClickAdd={onAdd[2]}
          onClickRemove={onRemove[2]}
        />
      </React.Fragment>,
    );
  } else {
    for (let i = 0; i < value.length; i += 2) {
      rangeSelectors.push(
        <div className={styles.subSelectorContainer} key={i}>
          <RangeSelector
            key={i}
            range={getRange(range, value, i)}
            value={value[i]}
            onChange={onChange[i]}
            onClickAdd={onAdd[i]}
            onClickRemove={onRemove[i]}
          />
          <RangeSelector
            key={i + 1}
            range={getRange(range, value, i + 1)}
            value={value[i + 1]}
            onChange={onChange[i + 1]}
            onClickAdd={onAdd[i + 1]}
            onClickRemove={onRemove[i + 1]}
          />
        </div>,
      );
    }
  }

  let selectorContainerStyle = { display: 'flex' };
  if (value.length === 1 && maintainSelectorHeight) selectorContainerStyle = { visibility: 'hidden' };
  else if (!maintainSelectorHeight) selectorContainerStyle = { display: 'none' };

  return (
    <div>
      <motion.div
        variants={sliderWrapperVariants}
        initial="hidden"
        animate="visible"
        className={`${styles.selectorsContainer} ${className}`}
        style={selectorContainerStyle}
      >
        {rangeSelectors}
      </motion.div>
      <div className={`${styles.container} ${className}`}>
        <AnimatePresence>
          {isSingle && (
            <motion.div
              variants={sliderWrapperVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className={styles.singleContainer}
            >
              {!disableRangeSelector && (
                <IconButton classes={{ root: classes.removeButtonRoot }} onClick={onRemove[0]}>
                  <WithSvg component={MinusSvg} size={10} />
                </IconButton>
              )}
              {children}
              {!disableRangeSelector && (
                <IconButton classes={{ root: classes.addButtonRoot }} onClick={onAdd[0]}>
                  <WithSvg component={PlusSvg} size={10} />
                </IconButton>
              )}
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {!isSingle && (
            <motion.div variants={sliderWrapperVariants} initial="hidden" animate="visible" exit="exit">
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

SliderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.arrayOf(PropTypes.func).isRequired,
  onAdd: PropTypes.arrayOf(PropTypes.func).isRequired,
  onRemove: PropTypes.arrayOf(PropTypes.func).isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  maintainSelectorHeight: PropTypes.bool,
  disableRangeSelector: PropTypes.bool,
  className: PropTypes.string,
};

SliderWrapper.defaultProps = {
  maintainSelectorHeight: true,
  disableRangeSelector: false,
  className: '',
};

export default SliderWrapper;
