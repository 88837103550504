const getElementBasedOnRange = (value, range, toAdd = true) => {
  let newValue;
  if (toAdd) {
    newValue = value + 1;
  } else {
    newValue = value - 1;
  }

  if (newValue < range[0]) {
    return range[0];
  }

  if (newValue > range[1]) {
    return range[1];
  }

  return newValue;
};

export const onClickAdd = (index, value, callback, range) => {
  const newValue = [];
  for (let i = 0; i < value.length; i += 1) {
    const element = value[i];
    if (index === i) {
      newValue.push(getElementBasedOnRange(element, range, true));
    } else {
      newValue.push(element);
    }
  }
  callback(undefined, newValue);
};

export const onClickRemove = (index, value, callback, range) => {
  const newValue = [];
  for (let i = 0; i < value.length; i += 1) {
    const element = value[i];
    if (index === i) {
      newValue.push(getElementBasedOnRange(element, range, false));
    } else {
      newValue.push(element);
    }
  }
  callback(undefined, newValue);
};

export const onChangeRangeValue = (index, value, currentValue, callback) => {
  const newValue = [];
  for (let i = 0; i < value.length; i += 1) {
    const element = value[i];
    if (index === i) {
      newValue.push(currentValue);
    } else {
      newValue.push(element);
    }
  }
  callback(undefined, newValue);
};

const randomIntBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const pushNumbersBetween = (numbers, min, max) => {
  for (let i = min; i <= max; i += 1) {
    numbers.push(i);
  }
};

export const randomIntFromRanges = (array) => {
  if (array.length % 2 !== 0) throw new TypeError('Array length need to be even');
  if (array == null) throw new TypeError("Array can't be null");
  const numbers = [];

  for (let i = 0; i < array.length; i += 2) {
    pushNumbersBetween(numbers, array[i], array[i + 1]);
  }

  return numbers[randomIntBetween(0, numbers.length - 1)];
};

export const changeSliderValue = (value, setValue, middleValue, isWithMiddleValue) => {
  if (isWithMiddleValue) {
    const newValue = [];
    if (value[0] > middleValue) {
      newValue.push(middleValue);
    } else {
      newValue.push(value[0]);
    }
    if (value[1] < middleValue) {
      newValue.push(middleValue);
    } else {
      newValue.push(value[1]);
    }
    setValue(newValue);
  } else {
    setValue(value);
  }
};
