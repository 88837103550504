import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PlusSvg } from 'svgs/plus.svg';
import { ReactComponent as MinusSvg } from 'svgs/minus.svg';

import { TextField, IconButton, makeStyles } from '@material-ui/core';
import WithSvg from 'components/WithSvg/WithSvg';
import { PRIMARY_COLOR } from 'utils/constants';
import styles from './RangeSelector.module.css';

const useStyles = makeStyles(() => ({
  iconButtonRoot: {
    padding: 4,
    background: PRIMARY_COLOR,
    '&:hover': {
      background: PRIMARY_COLOR,
    },
  },
  inputRoot: {
    '& input': {
      textAlign: 'center',
      background: PRIMARY_COLOR,
      color: '#fff',
      padding: '2px 0',
      fontSize: '12px',
      width: '2.2rem',
    },
    '&': {
      margin: '0 0.4rem',
    },
  },
}));

const RangeSelector = (props) => {
  const { onClickAdd, value, onChange, onClickRemove, range, className } = props;

  const classes = useStyles();

  const [currentValue, setCurrentValue] = useState(0);

  const onChangeValue = useCallback(
    (event) => {
      const newValue = Number(event.target.value);
      if (newValue < range[0]) {
        onChange(range[0]);
        setCurrentValue(range[0]);
      } else if (newValue > range[1]) {
        onChange(range[1]);
        setCurrentValue(range[1]);
      } else {
        onChange(newValue);
        setCurrentValue(newValue);
      }
    },
    [onChange, range],
  );

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={`${styles.container} ${className}`}>
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onClickRemove}>
        <WithSvg component={MinusSvg} size={10} />
      </IconButton>
      <TextField
        type="number"
        min={range[0]}
        max={range[1]}
        onChange={onChangeValue}
        InputProps={{ classes: { root: classes.inputRoot } }}
        value={currentValue}
      />
      <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onClickAdd}>
        <WithSvg component={PlusSvg} size={10} />
      </IconButton>
    </div>
  );
};

RangeSelector.propTypes = {
  onClickAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
};

RangeSelector.defaultProps = {
  className: '',
};

export default RangeSelector;
