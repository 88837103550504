import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PadUnlockedSvg } from 'svgs/padunlocked.svg';
import { ReactComponent as PadLockedSvg } from 'svgs/padlocked.svg';
import WithSvg from 'components/WithSvg/WithSvg';
import { makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  iconButtonRoot: {
    boxShadow: '0 0.1rem 0.3rem rgba(0, 0, 0, 0.2)',
    background: '#fff',
  },
}));

const BlockButton = (props) => {
  const { isBlocked, onClick } = props;

  const classes = useStyles();

  return (
    <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onClick}>
      <WithSvg component={isBlocked ? PadUnlockedSvg : PadLockedSvg} size={20} />
    </IconButton>
  );
};

BlockButton.propTypes = {
  isBlocked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BlockButton;
