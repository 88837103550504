import React, { useCallback, useState, useContext } from 'react';
// import PropTypes from 'prop-types';

import ColorBoxGenerator from 'components/ColorBoxGenerator/ColorBoxGenerator';
import SlidersBox from 'components/SlidersBox/SlidersBox';
import { ColorContext } from 'context/ColorContext';
import {
  RGB_2_HANDLES,
  RGB_4_HANDLES,
  HUE_4_HANDLES,
  HUE_2_HANDLES,
  SATURATION_4_HANDLES,
  LIGHTNESS_4_HANDLES,
  SATURATION_2_HANDLES,
  LIGHTNESS_2_HANDLES,
} from 'utils/constants';
import styles from './Main.module.css';

const Main = () => {
  const [isRgb, setIsRgb] = useState(false);
  const [onFourHandles, setOnFourHandles] = useState(false);

  const { rgb, hsl, setSeedColor } = useContext(ColorContext);
  const { setRed, setGreen, setBlue } = rgb;
  const { setHue, setSaturation, setLightness } = hsl;

  const toggleIsRgb = useCallback(() => {
    setIsRgb(!isRgb);
  }, [isRgb]);

  const setRGBs = useCallback(
    (value) => {
      setRed(value);
      setGreen(value);
      setBlue(value);
    },
    [setRed, setGreen, setBlue],
  );

  const toggleOnFourHandles = useCallback(() => {
    const newValue = !onFourHandles;
    if (newValue) {
      setSeedColor('transparent');
      setRGBs(RGB_4_HANDLES);
      setHue(HUE_4_HANDLES);
      setSaturation(SATURATION_4_HANDLES);
      setLightness(LIGHTNESS_4_HANDLES);
    } else {
      setRGBs(RGB_2_HANDLES);
      setHue(HUE_2_HANDLES);
      setSaturation(SATURATION_2_HANDLES);
      setLightness(LIGHTNESS_2_HANDLES);
    }
    setOnFourHandles(newValue);
  }, [onFourHandles, setSeedColor, setRGBs, setHue, setSaturation, setLightness]);

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <div className={styles.sliderBox}>
          <SlidersBox
            isRgb={isRgb}
            toggleIsRgb={toggleIsRgb}
            onFourHandles={onFourHandles}
            toggleOnFourHandles={toggleOnFourHandles}
          />
        </div>
        <div className={styles.colorBox}>
          <ColorBoxGenerator isRgb={isRgb} onFourHandles={onFourHandles} toggleOnFourHandles={toggleOnFourHandles} />
        </div>
      </div>
    </div>
  );
};

Main.propTypes = {};

export default Main;
