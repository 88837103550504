export const sliderStyle = {
  root: {
    height: 20,
    padding: 0,
  },
  thumb: {
    height: 'calc(100% + 2.5rem)',
    width: '1rem',
    marginLeft: '-0.5rem',
    backgroundColor: '#fff',
    cursor: 'pointer',
    boxShadow: '0 0.1rem 0.5rem rgba(0,0,0,.3)',
    borderRadius: '.3rem',
    top: '-0.7rem',
  },
  active: {},
  track: {
    backgroundColor: 'transparent',
    height: 20,
  },
  rail: {
    backgroundColor: 'transparent',
    opacity: 1,
    height: 20,
  },
};

export const verticalSliderStyle = {
  root: {
    width: '20px !important',
    padding: '0px !important',
  },
  rail: {
    opacity: 1,
    backgroundColor: 'transparent',
    width: '20px !important',
  },
  track: {
    backgroundColor: 'transparent',
    width: '20px !important',
  },
  thumb: {
    height: '1rem',
    width: 'calc(100% + 2.5rem)',
    backgroundColor: '#fff',
    cursor: 'pointer',
    boxShadow: '0 0.1rem 0.5rem rgba(0,0,0,.3)',
    borderRadius: '.3rem',
    left: '-0.9rem',
  },
};
