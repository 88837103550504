import React, { useCallback, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import WithSvg from 'components/WithSvg/WithSvg';
import { ReactComponent as QuestionSvg } from 'svgs/question.svg';
import { IconButton } from '@material-ui/core';
import { ColorContext } from 'context/ColorContext';
import { RGB_START, RGB_MIDDLE, RGB_MIDDLE_SECOND, RGB_END, DELAY, NO_RANDOM_COLORS } from 'utils/constants';
import { randomIntFromRanges } from 'utils/helpers';

const TIMEOUT_BEFORE_CLICK = 1200;
const TIME_WHEN_CLICKING = 500;

const getInitElements = () => {
  const cursor = document.getElementById('cursor');
  cursor.style.display = 'block';
  const overlay = document.getElementById('overlay');
  overlay.style.display = 'block';
  const cursorWrapper = document.getElementById('cursorWrapper');

  return { cursor, cursorWrapper };
};

const moveToClick = (cursor, cursorWrapper, id) => () => {
  const moveElement = document.getElementById(id);
  if (moveElement) {
    const boundaries = moveElement.getBoundingClientRect();
    // eslint-disable-next-line no-param-reassign
    cursor.style.transform = `translate(${boundaries.x + boundaries.width / 2}px, ${
      boundaries.y + boundaries.height / 2
    }px)`;
    setTimeout(() => {
      moveElement.click();
      cursorWrapper.classList.add('expand');
      setTimeout(() => {
        cursorWrapper.classList.remove('expand');
      }, TIME_WHEN_CLICKING);
    }, TIMEOUT_BEFORE_CLICK);
  } else {
    setTimeout(() => {
      setTimeout(() => {}, TIME_WHEN_CLICKING);
    }, TIME_WHEN_CLICKING);
  }
};

const runSimulation = (array, time) => {
  const intervalIds = [];
  array[0]();
  for (let i = 1; i < array.length; i += 1) {
    const element = array[i];
    if (element.func) {
      intervalIds.push(
        setTimeout(() => {
          element.func();
        }, element.wait),
      );
    } else {
      intervalIds.push(
        setTimeout(() => {
          element();
        }, i * time),
      );
    }
  }

  return intervalIds;
};

const getRandomSelections = () => {
  const numbers = [];

  while (numbers.length !== 3) {
    const randomNumber = randomIntFromRanges([0, 8]);
    if (numbers.includes(randomNumber)) {
      // eslint-disable-next-line no-continue
      continue;
    } else {
      numbers.push(randomNumber);
    }
  }

  return numbers;
};

const HelpButton = (props) => {
  const { setAppKey } = props;

  const {
    rgb: { setRed, setBlue, resetRgbState },
    hsl: { resetHslState },
  } = useContext(ColorContext);

  const [intervalIds, setIntervalIds] = useState();

  const resetContextState = useCallback(() => {
    resetRgbState();
    resetHslState();
  }, [resetRgbState, resetHslState]);

  useEffect(() => {
    const overlay = document.getElementById('overlay');
    const cursor = document.getElementById('cursor');
    const onClickOverlay = () => {
      overlay.style.display = 'none';
      cursor.style.display = 'none';
      cursor.style.transform = 'translate(0, 0)';

      intervalIds.forEach((interval) => {
        clearTimeout(interval);
      });
      resetContextState();
      setAppKey(`${Math.random()}`);
    };
    overlay.addEventListener('click', onClickOverlay);

    return () => {
      overlay.removeEventListener('click', onClickOverlay);
    };
  }, [intervalIds, resetContextState, resetHslState, resetRgbState, setAppKey]);

  const resetHelp = useCallback(() => {
    const overlay = document.getElementById('overlay');
    const cursor = document.getElementById('cursor');
    cursor.style.display = 'none';
    overlay.style.display = 'none';
  }, []);

  const onClickButton = useCallback(() => {
    resetContextState();
    const { cursor, cursorWrapper } = getInitElements();
    const time = TIMEOUT_BEFORE_CLICK + TIME_WHEN_CLICKING;
    const randomSelections = getRandomSelections();
    const intervals = runSimulation(
      [
        moveToClick(cursor, cursorWrapper, '2Th4Th'),
        moveToClick(cursor, cursorWrapper, 'rgbHsl'),
        () => {
          moveToClick(cursor, cursorWrapper, 'red')();
          setTimeout(() => {
            setRed([RGB_START, RGB_MIDDLE, RGB_MIDDLE_SECOND, RGB_END]);
          }, TIMEOUT_BEFORE_CLICK);
        },
        () => {
          moveToClick(cursor, cursorWrapper, 'blue')();
          setTimeout(() => {
            setBlue([RGB_START, RGB_MIDDLE, RGB_MIDDLE_SECOND, RGB_END]);
          }, TIMEOUT_BEFORE_CLICK);
        },
        moveToClick(cursor, cursorWrapper, 'generateColors'),
        {
          func: moveToClick(cursor, cursorWrapper, `colorBox${randomSelections[0]}`),
          wait: 5 * time + NO_RANDOM_COLORS * DELAY,
        },
        {
          func: moveToClick(cursor, cursorWrapper, `colorBox${randomSelections[1]}`),
          wait: 6 * time + NO_RANDOM_COLORS * DELAY,
        },
        {
          func: moveToClick(cursor, cursorWrapper, `colorBox${randomSelections[2]}`),
          wait: 7 * time + NO_RANDOM_COLORS * DELAY,
        },
        {
          func: moveToClick(cursor, cursorWrapper, 'saveColors'),
          wait: 8 * time + NO_RANDOM_COLORS * DELAY,
        },
        {
          func: () => {},
          wait: 9 * time + NO_RANDOM_COLORS * DELAY,
        },
        {
          func: moveToClick(cursor, cursorWrapper, 'resetColors'),
          wait: 11 * time + NO_RANDOM_COLORS * DELAY,
        },
        {
          func: () => {
            resetHelp();
          },
          wait: 12 * time + NO_RANDOM_COLORS * DELAY,
        },
      ],
      time,
    );
    setIntervalIds(intervals);
  }, [resetContextState, resetHelp, setBlue, setRed]);

  return (
    <IconButton onClick={onClickButton}>
      <WithSvg component={QuestionSvg} />
    </IconButton>
  );
};

HelpButton.propTypes = {
  setAppKey: PropTypes.func.isRequired,
};

export default HelpButton;
