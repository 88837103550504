import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core';

import Main from 'components/Main/Main';
import ApplicationBar from 'components/ApplicationBar/ApplicationBar';
import Notification from 'components/Notification/Notification';
import LocationContextProvider from 'context/ColorContext';
import MouseCursor from 'components/MouseCursor/MouseCursor';
import { store, persistor } from './store';
import theme from './theme';

function App() {
  const [appKey, setAppKey] = useState('1');

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocationContextProvider>
            <div className="App" key={appKey}>
              <MouseCursor />
              <ApplicationBar setAppKey={setAppKey} />
              <Main />
            </div>
          </LocationContextProvider>
          <Notification />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
