import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  HUE_INITIAL_START,
  HUE_INITIAL_END,
  SATURATION_INITIAL_START,
  SATURATION_INITIAL_END,
  LIGHTNESS_INITIAL_START,
  LIGHTNESS_INITIAL_END,
  RGB_END,
  RGB_START,
  HUE_END,
  SATURATION_END,
  LIGHTNESS_END,
} from 'utils/constants';
import {
  getHslBasedOnRgbArrays,
  getRgbBasedOnHslArrays,
  getHslHandleBasedOnSeedColor,
  getRgbHandleBasedOnSeedColor,
} from 'utils/colorHelper';
import chroma from 'chroma-js';

export const ColorContext = React.createContext({
  hsl: {
    hue: [HUE_INITIAL_START, HUE_INITIAL_END],
    saturation: [SATURATION_INITIAL_START, SATURATION_INITIAL_END],
    lightness: [LIGHTNESS_INITIAL_START, LIGHTNESS_INITIAL_END],
    setHue: () => {},
    setSaturation: () => {},
    setLightness: () => {},
    resetHslState: () => {},
    hueMiddle: -1,
    saturationMiddle: -1,
    lightnessMiddle: -1,
    oldHue: [],
    setOldHue: () => {},
    oldSaturation: [],
    setOldSaturation: () => {},
    oldLightness: [],
    setOldLightness: () => {},
  },
  rgb: {
    red: [HUE_INITIAL_START, HUE_INITIAL_END],
    blue: [HUE_INITIAL_START, HUE_INITIAL_END],
    green: [HUE_INITIAL_START, HUE_INITIAL_END],
    setRed: () => {},
    setGreen: () => {},
    setBlue: () => {},
    resetRgbState: () => {},
    redMiddle: -1,
    greenMiddle: -1,
    blueMiddle: -1,
    oldRed: [],
    setOldRed: () => {},
    oldGreen: [],
    setOldGreen: () => {},
    oldBlue: [],
    setOldBlue: () => {},
  },
  setSeedColor: () => {},
  seedColor: '',
  changeHslBasedOnRgb: () => {},
  changeRgbBasedOnHsl: () => {},
  setDefaultHslBasedOnSeedColor: () => {},
  setDefaultRgbBasedOnSeedColor: () => {},
  resetMiddleValues: () => {},
});

const ColorContextProvider = (props) => {
  const { children } = props;

  const [hue, setHue] = useState([HUE_INITIAL_START, HUE_INITIAL_END]);
  const [saturation, setSaturation] = useState([SATURATION_INITIAL_START, SATURATION_INITIAL_END]);
  const [lightness, setLightness] = useState([LIGHTNESS_INITIAL_START, LIGHTNESS_INITIAL_END]);

  const [oldHue, setOldHue] = useState([HUE_INITIAL_START, HUE_INITIAL_END]);
  const [oldSaturation, setOldSaturation] = useState([SATURATION_INITIAL_START, SATURATION_INITIAL_END]);
  const [oldLightness, setOldLightness] = useState([LIGHTNESS_INITIAL_START, LIGHTNESS_INITIAL_END]);

  const [hueMiddle, setHueMiddle] = useState(0);
  const [saturationMiddle, setSaturationMiddle] = useState(0);
  const [lightnessMiddle, setLightnessMiddle] = useState(0);

  const [red, setRed] = useState([RGB_START, RGB_END]);
  const [green, setGreen] = useState([RGB_START, RGB_END]);
  const [blue, setBlue] = useState([RGB_START, RGB_END]);

  const [oldRed, setOldRed] = useState([RGB_START, RGB_END]);
  const [oldGreen, setOldGreen] = useState([RGB_START, RGB_END]);
  const [oldBlue, setOldBlue] = useState([RGB_START, RGB_END]);

  const [redMiddle, setRedMiddle] = useState(0);
  const [greenMiddle, setGreenMiddle] = useState(0);
  const [blueMiddle, setBlueMiddle] = useState(0);

  const [seedColor, setSeedColor] = useState('transparent');

  const resetHslState = useCallback(() => {
    setHue([HUE_INITIAL_START, HUE_INITIAL_END]);
    setSaturation([SATURATION_INITIAL_START, SATURATION_INITIAL_END]);
    setLightness([LIGHTNESS_INITIAL_START, LIGHTNESS_INITIAL_END]);
  }, []);

  const resetRgbState = useCallback(() => {
    setRed([RGB_START, RGB_END]);
    setGreen([RGB_START, RGB_END]);
    setBlue([RGB_START, RGB_END]);
  }, []);

  const changeHslBasedOnRgb = useCallback(
    // eslint-disable-next-line no-shadow
    (red, green, blue) => {
      const newHsl = getHslBasedOnRgbArrays(red, green, blue);

      if (seedColor !== 'transparent') {
        setHueMiddle(Math.round((newHsl[0][0] + newHsl[0][1]) / 2));
        setSaturationMiddle(Math.round((newHsl[1][0] + newHsl[1][1]) / 2));
        setLightnessMiddle(Math.round((newHsl[2][0] + newHsl[2][1]) / 2));
      }

      setHue(newHsl[0]);
      setSaturation(newHsl[1]);
      setLightness(newHsl[2]);
    },
    [seedColor],
  );

  const changeRgbBasedOnHsl = useCallback(
    // eslint-disable-next-line no-shadow
    (hue, saturation, lightness) => {
      const newRgb = getRgbBasedOnHslArrays(hue, saturation, lightness);

      if (seedColor !== 'transparent') {
        setRedMiddle(Math.round((newRgb[0][0] + newRgb[0][1]) / 2));
        setGreenMiddle(Math.round((newRgb[1][0] + newRgb[1][1]) / 2));
        setBlueMiddle(Math.round((newRgb[2][0] + newRgb[2][1]) / 2));
      }

      setRed(newRgb[0]);
      setGreen(newRgb[1]);
      setBlue(newRgb[2]);
    },
    [seedColor],
  );

  const setDefaultHslBasedOnSeedColor = useCallback((pickedColor) => {
    const seedColorHsl = chroma(pickedColor).hsl();
    setHueMiddle(Math.round(seedColorHsl[0]));
    setSaturationMiddle(Math.round(seedColorHsl[1] * 100));
    setLightnessMiddle(Math.round(seedColorHsl[2] * 100));
    setHue(getHslHandleBasedOnSeedColor(0, pickedColor, HUE_END));
    setSaturation(getHslHandleBasedOnSeedColor(1, pickedColor, SATURATION_END, true));
    setLightness(getHslHandleBasedOnSeedColor(2, pickedColor, LIGHTNESS_END, true));
  }, []);

  const setDefaultRgbBasedOnSeedColor = useCallback((pickedColor) => {
    const seedColorRgb = chroma(pickedColor).rgb();
    setRedMiddle(Math.round(seedColorRgb[0]));
    setGreenMiddle(Math.round(seedColorRgb[1]));
    setBlueMiddle(Math.round(seedColorRgb[2]));
    setRed(getRgbHandleBasedOnSeedColor(0, pickedColor, RGB_END));
    setGreen(getRgbHandleBasedOnSeedColor(1, pickedColor, RGB_END));
    setBlue(getRgbHandleBasedOnSeedColor(2, pickedColor, RGB_END));
  }, []);

  const resetMiddleValues = useCallback(() => {
    setHueMiddle(-1);
    setSaturationMiddle(-1);
    setLightnessMiddle(-1);
    setRedMiddle(-1);
    setGreenMiddle(-1);
    setBlueMiddle(-1);
  }, []);

  return (
    <ColorContext.Provider
      value={{
        hsl: {
          hue,
          saturation,
          lightness,
          setHue,
          setSaturation,
          setLightness,
          resetHslState,
          hueMiddle,
          saturationMiddle,
          lightnessMiddle,
          oldHue,
          setOldHue,
          oldSaturation,
          setOldSaturation,
          oldLightness,
          setOldLightness,
        },
        rgb: {
          red,
          blue,
          green,
          setRed,
          setGreen,
          setBlue,
          resetRgbState,
          redMiddle,
          greenMiddle,
          blueMiddle,
          oldRed,
          setOldRed,
          oldGreen,
          setOldGreen,
          oldBlue,
          setOldBlue,
        },
        seedColor,
        setSeedColor,
        changeHslBasedOnRgb,
        changeRgbBasedOnHsl,
        setDefaultHslBasedOnSeedColor,
        setDefaultRgbBasedOnSeedColor,
        resetMiddleValues,
      }}
    >
      {children}
    </ColorContext.Provider>
  );
};

ColorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ColorContextProvider;
