import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import SliderWrapper from 'components/SliderWrapper/SliderWrapper';
import { onClickAdd, onClickRemove, onChangeRangeValue } from 'utils/helpers';
import { withStyles, Slider } from '@material-ui/core';
import { sliderStyle } from 'utils/commonStyles';
import { ColorContext } from 'context/ColorContext';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import styles from './ColorSlider.module.css';

const CustomSlider = withStyles({
  ...sliderStyle,
  valueLabel: {
    left: 'calc(-50% + 1.4rem)',
    top: 13,
    fontSize: '0.9rem',
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  thumb: (props) =>
    props.circleThumb
      ? {
          borderRadius: '50%',
          width: '2.5rem',
          height: '2.5rem',
          marginLeft: '-1.25rem',
          boxShadow: '0 0.1rem 0.5rem rgba(0,0,0,.3)',
          backgroundColor: '#fff',
          top: -6,
        }
      : {
          ...sliderStyle.thumb,
          '&.Mui-disabled': {
            display: 'none',
          },
        },
  root: {
    ...sliderStyle.root,
    background: (props) => props.backgroundGradient,
  },
})(Slider);

const ColorSlider = (props) => {
  const {
    min,
    max,
    value,
    onChange,
    refSlider,
    sliderWrapperProps,
    circleThumb,
    sliderProps,
    range,
    middleValue,
    id,
  } = props;

  const { seedColor } = useContext(ColorContext);

  const disabledThumbRef = useRef();
  const sliderContainerRef = useRef();

  useEffect(() => {
    if (
      disabledThumbRef &&
      disabledThumbRef.current &&
      sliderContainerRef &&
      sliderContainerRef.current &&
      middleValue > 0 &&
      seedColor !== 'transparent'
    ) {
      const thumb = disabledThumbRef.current;

      thumb.style.left = `calc(${((middleValue - min) * 100) / (max - min)}% - 8px)`;
      thumb.style.display = 'block';
      thumb.style.top = '-18px';

      sliderContainerRef.current.style.left = 0;
      sliderContainerRef.current.style.top = 0;
    }
  }, [max, min, refSlider, value, seedColor, middleValue, disabledThumbRef, sliderContainerRef]);

  return (
    <ErrorBoundary>
      <SliderWrapper
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...sliderWrapperProps}
        disableRangeSelector={sliderProps.disabled}
        range={range}
        value={value}
        onChange={value.map((_, i) => (newValue) => onChangeRangeValue(i, value, newValue, onChange))}
        onAdd={value.map((_, i) => () => onClickAdd(i, value, onChange, range))}
        onRemove={value.map((_, i) => () => onClickRemove(i, value, onChange, range))}
      >
        <div className={styles.sliderContainer} ref={sliderContainerRef}>
          <CustomSlider
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sliderProps}
            circleThumb={circleThumb}
            ref={refSlider}
            min={min}
            max={max}
            value={value}
            onChange={onChange}
            id={id}
          />
          {middleValue >= 0 && seedColor !== 'transparent' ? (
            <div className={styles.thumb} ref={disabledThumbRef} />
          ) : null}
        </div>
      </SliderWrapper>
    </ErrorBoundary>
  );
};

ColorSlider.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  refSlider: PropTypes.objectOf(PropTypes.any),
  sliderWrapperProps: PropTypes.objectOf(PropTypes.any),
  circleThumb: PropTypes.bool,
  sliderProps: PropTypes.objectOf(PropTypes.any),
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  middleValue: PropTypes.number,
  id: PropTypes.string,
};

ColorSlider.defaultProps = {
  refSlider: undefined,
  sliderWrapperProps: {},
  circleThumb: false,
  sliderProps: {},
  middleValue: undefined,
  id: 'colorSlider',
};

export default React.memo(ColorSlider);
