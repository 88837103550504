export const HUE_START = 0;
export const HUE_END = 360;
export const HUE_MIDDLE = Math.round((HUE_END + HUE_START) / 2);
export const HUE_INITIAL_START = 30;
export const HUE_INITIAL_END = 330;
export const HUE_MIDDLE_FIRST = 90;
export const HUE_MIDDLE_SECOND = 270;
export const HUE_4_HANDLES = [HUE_START, HUE_MIDDLE_FIRST, HUE_MIDDLE_SECOND, HUE_END];
export const HUE_2_HANDLES = [HUE_INITIAL_START, HUE_INITIAL_END];

export const SATURATION_START = 0;
export const SATURATION_END = 100;
export const SATURATION_MIDDLE = Math.round((SATURATION_END + SATURATION_START) / 2);
export const SATURATION_INITIAL_START = 10;
export const SATURATION_INITIAL_END = 90;
export const SATURATION_MIDDLE_FIRST = 25;
export const SATURATION_MIDDLE_SECOND = 75;
export const SATURATION_4_HANDLES = [
  SATURATION_START,
  SATURATION_MIDDLE_FIRST,
  SATURATION_MIDDLE_SECOND,
  SATURATION_END,
];
export const SATURATION_2_HANDLES = [SATURATION_INITIAL_START, SATURATION_INITIAL_END];

export const LIGHTNESS_START = 0;
export const LIGHTNESS_END = 100;
export const LIGHTNESS_MIDDLE = Math.round((LIGHTNESS_END + LIGHTNESS_START) / 2);
export const LIGHTNESS_INITIAL_START = 10;
export const LIGHTNESS_INITIAL_END = 90;
export const LIGHTNESS_MIDDLE_FIRST = 25;
export const LIGHTNESS_MIDDLE_SECOND = 75;
export const LIGHTNESS_4_HANDLES = [LIGHTNESS_START, LIGHTNESS_MIDDLE_FIRST, LIGHTNESS_MIDDLE_SECOND, LIGHTNESS_END];
export const LIGHTNESS_2_HANDLES = [LIGHTNESS_INITIAL_START, LIGHTNESS_INITIAL_END];

export const RGB_START = 0;
export const RGB_END = 255;
export const RGB_MIDDLE = Math.round((RGB_END + RGB_START) / 2);
export const RGB_MIDDLE_FIRST = 64;
export const RGB_MIDDLE_SECOND = 190;

export const RGB_4_HANDLES = [RGB_START, RGB_MIDDLE_FIRST, RGB_MIDDLE_SECOND, RGB_END];
export const RGB_2_HANDLES = [RGB_START, RGB_END];

export const PRIMARY_COLOR = '#2B3245';
export const RGB_NOT_IN_RANGE_COLOR = '#7281a7';
export const DISABLED_COLOR = '#BDBDBD';

export const RED_GRADIENT_CSS = 'linear-gradient(to right, hsl(0, 0%, 50%), hsl(0, 100%, 50%))';
export const GREEN_GRADIENT_CSS = 'linear-gradient(to right, hsl(120, 0%, 50%), hsl(120, 100%, 50%))';
export const BLUE_GRADIENT_CSS = 'linear-gradient(to right, hsl(225, 0%, 50%), hsl(225, 100%, 50%))';

export const DELAY = 500;
export const NO_RANDOM_COLORS = 9;
