import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Modal, IconButton, withWidth } from '@material-ui/core';

import { ReactComponent as CloseSvg } from 'svgs/close.svg';
import WithSvg from 'components/WithSvg/WithSvg';
import styles from './ModalContainer.module.css';

const TOP = '64px';

const useStyles = makeStyles((theme) => ({
  backdropRoot: {
    backgroundColor: '#F4F9FF !important',
    top: `${TOP} !important`,
    [theme.breakpoints.down('sm')]: {
      top: `0 !important`,
    },
  },
}));

const ModalContainer = (props) => {
  const { open, onClose, children, width } = props;

  const classes = useStyles();

  return (
    <Modal
      style={{ top: width === 'xs' || width === 'sm' ? 0 : TOP }}
      open={open}
      onClose={onClose}
      BackdropProps={{ className: classes.backdropRoot }}
    >
      <div className={styles.container}>
        <div className={styles.closeIcon}>
          <IconButton onClick={onClose}>
            <WithSvg component={CloseSvg} />
          </IconButton>
        </div>
        {children}
      </div>
    </Modal>
  );
};

ModalContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(ModalContainer);
