import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Button, makeStyles, Modal } from '@material-ui/core';

import {
  HUE_MIDDLE,
  HUE_START,
  HUE_END,
  SATURATION_START,
  SATURATION_END,
  LIGHTNESS_MIDDLE,
  SATURATION_MIDDLE,
  LIGHTNESS_START,
  LIGHTNESS_END,
  RED_GRADIENT_CSS,
  GREEN_GRADIENT_CSS,
  BLUE_GRADIENT_CSS,
  RGB_START,
  RGB_END,
  RGB_MIDDLE,
} from 'utils/constants';
import ColorSlider from 'components/ColorSlider/ColorSlider';
import { ColorContext } from 'context/ColorContext';
import {
  generateCSSHueGradient,
  getSaturationBackground,
  getLightnessBackground,
  getHexColor,
  getRgbBasedOnHslArrays,
  getHslBasedOnRgbArrays,
} from 'utils/colorHelper';
import { ReactComponent as SaveSvg } from 'svgs/save.svg';
import { ReactComponent as ReloadSvg } from 'svgs/reload.svg';
import WithSvg from 'components/WithSvg/WithSvg';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import styles from './SeedColorPickerModal.module.css';

const useStyles = makeStyles((theme) => ({
  toggleButtonRoot: {
    color: theme.palette.primary.main,
    paddingTop: '0.3rem',
    paddingBottom: '0.5rem',
    border: 'none',
    borderRadius: '0',
    background: 'transparent',
    borderBottom: '1px solid #E2E2E2',
  },
  selected: {
    color: `${theme.palette.primary.main} !important`,
    background: 'transparent !important',
    borderBottom: '2px solid #496FAD !important',
  },
}));

const SeedColorPickerModal = (props) => {
  const { open, onClose, onFourHandles, toggleOnFourHandles } = props;

  const classes = useStyles();

  const [alignment, setAlignment] = useState('left');

  const [hue, setHue] = useState([HUE_MIDDLE]);
  const [saturation, setSaturation] = useState([SATURATION_MIDDLE]);
  const [lightness, setLightness] = useState([LIGHTNESS_MIDDLE]);

  const [red, setRed] = useState([RGB_MIDDLE]);
  const [green, setGreen] = useState([RGB_MIDDLE]);
  const [blue, setBlue] = useState([RGB_MIDDLE]);

  const [pickedColor, setPickedColor] = useState(
    getHexColor({ h: hue[0], s: saturation[0] / 100, l: lightness[0] / 100 }),
  );

  const {
    setSeedColor,
    hsl: { resetHslState },
    rgb: { resetRgbState },
    setDefaultHslBasedOnSeedColor,
    setDefaultRgbBasedOnSeedColor,
    resetMiddleValues,
    seedColor,
  } = useContext(ColorContext);

  const saturationSliderRef = useRef(null);
  const lightnessSliderRef = useRef(null);

  // eslint-disable-next-line no-shadow
  const handleHslColorChanger = useCallback((hue, saturation, lightness) => {
    const hsl = { h: hue, s: saturation / 100, l: lightness / 100 };
    const hexColor = getHexColor(hsl);
    setPickedColor(hexColor);
  }, []);

  // eslint-disable-next-line no-shadow
  const handleRgbColorChanger = useCallback((red, green, blue) => {
    const rgb = { r: red, g: green, b: blue };
    const hexColor = getHexColor(rgb);
    setPickedColor(hexColor);
  }, []);

  const handleAlignment = useCallback(
    (event, newAlignment) => {
      if (newAlignment === 'left') {
        handleHslColorChanger(hue[0], saturation[0], lightness[0]);
      } else if (newAlignment === 'right') {
        handleRgbColorChanger(red[0], green[0], blue[0]);
      }
      setAlignment(newAlignment);
    },
    [hue, saturation, lightness, red, green, blue, handleHslColorChanger, handleRgbColorChanger],
  );

  const setSelectedColor = useCallback(() => {
    if (alignment === 'left') {
      handleHslColorChanger(hue[0], saturation[0], lightness[0]);
    } else if (alignment === 'right') {
      handleRgbColorChanger(red[0], green[0], blue[0]);
    }
  }, [hue, saturation, lightness, red, green, blue, alignment, handleHslColorChanger, handleRgbColorChanger]);

  useEffect(() => {
    if (open) {
      setSelectedColor();
    }
  }, [open, setSelectedColor]);

  const changeRgbBasedOnHsl = useCallback(
    // eslint-disable-next-line no-shadow
    (hue, saturation, lightness) => {
      const newRgb = getRgbBasedOnHslArrays(hue, saturation, lightness);

      setRed(newRgb[0]);
      setGreen(newRgb[1]);
      setBlue(newRgb[2]);
    },
    [],
  );

  const changeHslBasedOnRgb = useCallback(
    // eslint-disable-next-line no-shadow
    (red, green, blue) => {
      const newHsl = getHslBasedOnRgbArrays(red, green, blue);

      setHue(newHsl[0]);
      setSaturation(newHsl[1]);
      setLightness(newHsl[2]);
    },
    [],
  );

  const onChangeHue = useCallback(
    (e, value) => {
      setHue(value);
      changeRgbBasedOnHsl(value, saturation, lightness);
      if (saturationSliderRef && saturationSliderRef.current) {
        saturationSliderRef.current.style.background = getSaturationBackground(value);
      }
      if (lightnessSliderRef && lightnessSliderRef.current)
        lightnessSliderRef.current.style.background = getLightnessBackground(value);
    },
    [changeRgbBasedOnHsl, lightness, saturation],
  );

  const handleToggleTwoFourHandles = useCallback(() => {
    if (onFourHandles) {
      toggleOnFourHandles();
    }
  }, [onFourHandles, toggleOnFourHandles]);

  const onClickReset = useCallback(() => {
    setSeedColor('transparent');
    setHue([HUE_MIDDLE]);
    setSaturation([SATURATION_MIDDLE]);
    setLightness([LIGHTNESS_MIDDLE]);
    setRed([RGB_MIDDLE]);
    setGreen([RGB_MIDDLE]);
    setBlue([RGB_MIDDLE]);
    onClose();
    handleToggleTwoFourHandles();
    resetHslState();
    resetRgbState();
    resetMiddleValues();
  }, [setSeedColor, onClose, handleToggleTwoFourHandles, resetHslState, resetRgbState, resetMiddleValues]);

  const onClickSave = useCallback(() => {
    setSeedColor(pickedColor);
    onClose();
    setDefaultHslBasedOnSeedColor(pickedColor);
    setDefaultRgbBasedOnSeedColor(pickedColor);
    handleToggleTwoFourHandles();
  }, [
    setSeedColor,
    pickedColor,
    onClose,
    setDefaultHslBasedOnSeedColor,
    setDefaultRgbBasedOnSeedColor,
    handleToggleTwoFourHandles,
  ]);

  const onChangeSaturation = useCallback(
    (e, value) => {
      changeRgbBasedOnHsl(hue, value, lightness);
      setSaturation(value);
    },
    [changeRgbBasedOnHsl, hue, lightness],
  );

  const onChangeLightness = useCallback(
    (e, value) => {
      changeRgbBasedOnHsl(hue, saturation, value);
      setLightness(value);
    },
    [changeRgbBasedOnHsl, hue, saturation],
  );

  const onChangeRed = useCallback(
    (e, value) => {
      setRed(value);
      changeHslBasedOnRgb(value, green, blue);
    },
    [blue, changeHslBasedOnRgb, green],
  );

  const onChangeBlue = useCallback(
    (e, value) => {
      setBlue(value);
      changeHslBasedOnRgb(value, green, value);
    },
    [changeHslBasedOnRgb, green],
  );

  const onChangeGreen = useCallback(
    (e, value) => {
      setGreen(value);
      changeHslBasedOnRgb(red, value, blue);
    },
    [blue, changeHslBasedOnRgb, red],
  );

  return (
    <ErrorBoundary>
      <Modal open={open} onClose={onClose}>
        <div className={styles.container}>
          <div className={styles.colorPickerContainer}>
            <div className={styles.colorPicker}>
              <div className={styles.pickedColorContainer}>
                <div className={styles.pickedColor} style={{ background: pickedColor }} />
              </div>
              <div className={styles.sliders}>
                {alignment === 'left' && (
                  <>
                    <ColorSlider
                      key="hue"
                      range={[HUE_START, HUE_END]}
                      sliderProps={{ backgroundGradient: generateCSSHueGradient(), valueLabelDisplay: 'on' }}
                      circleThumb
                      sliderWrapperProps={{ maintainSelectorHeight: false }}
                      min={HUE_START}
                      max={HUE_END}
                      value={hue}
                      onChange={onChangeHue}
                    />
                    <ColorSlider
                      key="saturation"
                      range={[SATURATION_START, SATURATION_END]}
                      refSlider={saturationSliderRef}
                      sliderProps={{ backgroundGradient: getSaturationBackground(HUE_MIDDLE), valueLabelDisplay: 'on' }}
                      circleThumb
                      sliderWrapperProps={{ maintainSelectorHeight: false }}
                      min={SATURATION_START}
                      max={SATURATION_END}
                      value={saturation}
                      onChange={onChangeSaturation}
                    />
                    <ColorSlider
                      key="lightness"
                      range={[LIGHTNESS_START, LIGHTNESS_END]}
                      refSlider={lightnessSliderRef}
                      sliderProps={{ backgroundGradient: getLightnessBackground(HUE_MIDDLE), valueLabelDisplay: 'on' }}
                      circleThumb
                      sliderWrapperProps={{ maintainSelectorHeight: false }}
                      min={LIGHTNESS_START}
                      max={LIGHTNESS_END}
                      value={lightness}
                      onChange={onChangeLightness}
                    />
                  </>
                )}
                {alignment === 'right' && (
                  <>
                    <ColorSlider
                      key="red"
                      range={[RGB_START, RGB_END]}
                      sliderProps={{ backgroundGradient: RED_GRADIENT_CSS, valueLabelDisplay: 'on' }}
                      circleThumb
                      sliderWrapperProps={{ maintainSelectorHeight: false }}
                      min={RGB_START}
                      max={RGB_END}
                      value={red}
                      onChange={onChangeRed}
                    />
                    <ColorSlider
                      key="green"
                      range={[RGB_START, RGB_END]}
                      refSlider={saturationSliderRef}
                      sliderProps={{ backgroundGradient: GREEN_GRADIENT_CSS, valueLabelDisplay: 'on' }}
                      circleThumb
                      sliderWrapperProps={{ maintainSelectorHeight: false }}
                      min={RGB_START}
                      max={RGB_END}
                      value={green}
                      onChange={onChangeGreen}
                    />
                    <ColorSlider
                      key="blue"
                      range={[RGB_START, RGB_END]}
                      refSlider={lightnessSliderRef}
                      sliderProps={{ backgroundGradient: BLUE_GRADIENT_CSS, valueLabelDisplay: 'on' }}
                      circleThumb
                      sliderWrapperProps={{ maintainSelectorHeight: false }}
                      min={RGB_START}
                      max={RGB_END}
                      value={blue}
                      onChange={onChangeBlue}
                    />
                  </>
                )}
              </div>
            </div>
            <div className={styles.controlPanel}>
              <div className={styles.pickedColorText}>{pickedColor}</div>
              <div className={styles.buttonsContainer}>
                <div className={styles.leftSide}>
                  <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment} aria-label="text alignment">
                    <ToggleButton
                      color="primary"
                      classes={{ selected: classes.selected, root: classes.toggleButtonRoot }}
                      value="left"
                      aria-label="left aligned"
                    >
                      Hsl
                    </ToggleButton>
                    <ToggleButton
                      color="primary"
                      classes={{ selected: classes.selected, root: classes.toggleButtonRoot }}
                      value="right"
                      aria-label="right aligned"
                    >
                      Rgb
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div className={styles.rightSide}>
                  <Button onClick={onClickReset} disabled={seedColor === 'transparent'}>
                    <WithSvg component={ReloadSvg} size={24} disabled={seedColor === 'transparent'} />
                  </Button>
                  <Button onClick={onClickSave}>
                    <WithSvg component={SaveSvg} size={24} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

SeedColorPickerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFourHandles: PropTypes.bool.isRequired,
  toggleOnFourHandles: PropTypes.func.isRequired,
};

export default SeedColorPickerModal;
